@import "~core/variables";
header {
    .header-spacer {
        border-color: $white;
    }
    .main-header {
        background-color: transparent;
        // @include media-breakpoint-up(lg) {
        //     background-color: $white;
        //     .main-menu {
        //         background-color: $white !important;
        //     }
        // }
    }
    &.stickyHeader {
        .menu-toggleable-left.navbar-toggleable-lg {
            @include media-breakpoint-up(lg) {
                background-color: #e0e7e7 !important;
            }
        }
    }
    &.stickyHeader.hover-menu {
        .menu-toggleable-left.navbar-toggleable-lg {
            @include media-breakpoint-up(lg) {
                background-color:  $white !important;
            }
        }
    }
}